.lia-action-delete {
  &:hover,
  &:not(:hover) {
    color: var(--lia-bs-danger);
  }
}

.lia-edits-disabled {
  font-size: small;
}
